<template>
    <div v-title title="SU模型">
        <div class="sec-top">
            <div class="container">
                <div class="logo">
                    <a href="">
                        <!-- <img src="https://su.znzmo.com/static/img/su_home_logo.png" alt=""> -->
                    </a>
                </div>
                <div class="ipt_container">
                    <div class="ipt">
                        <input type="text">
                        <div class="btn">
                            <img src="https://cdn.code.znzmo.com/newSu/search-icon-1.png" alt="">
                            <span>搜索</span>
                        </div>
                    </div>
                    <div class="ipt_more">
                        <a href="" class="hot">速领！5T设计资源</a>
                        <a href="">效果图作品</a>
                        <a href="">设计趋势</a>
                        <a href="">激励上传任务，得知币奖励</a>
                        <a href="">SU材质</a>
                        <a href="">精品SU课程</a>
                        <a href="">免费SU插件</a>
                        <a href="">免费软件</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="type-box">
            <div class="su_theme_category">
                <ul class="every_class_ul">
                    <li class="border_own every_class_li_0">
                        <img src="	https://cdn.code.znzmo.com/newSu-x/c0.png" alt="">
                        <a href="" class="category">
                            单体su模型
                        </a>
                        <span class="more">
                            <img src="https://cdn.code.znzmo.com/newSu-x/cateRight.png" alt="">
                        </span>
                        <div class="class_xiangqing_all">
                            <a href="">沙发</a>
                            <a href="">椅凳</a>
                            <a href="">床具</a>
                        </div>
                    </li>
                    <div class="every_class every_class_0">
                        <div class="every_class_list">
                            <div class="every_class_list_l">
                                <a href="">客/餐厅</a>
                                <img src="https://cdn.code.znzmo.com/newSu-x/cateRight.png" alt="">
                            </div>
                            <div class="every_class_list_r">
                                <p>
                                    <a href="">家居客厅</a>
                                </p>
                                <p>
                                    <a href="">别墅客厅</a>
                                </p>
                                <p>
                                    <a href="">客餐厅</a>
                                </p>
                                <p>
                                    <a href="">家居餐厅</a>
                                </p>
                                <p>
                                    <a href="">家居吧台区</a>
                                </p>
                            </div>
                        </div>
                        <div class="every_class_list">
                            <div class="every_class_list_l">
                                <a href="">客/餐厅</a>
                                <img src="https://cdn.code.znzmo.com/newSu-x/cateRight.png" alt="">
                            </div>
                            <div class="every_class_list_r">
                                <p>
                                    <a href="">家居客厅</a>
                                </p>
                                <p>
                                    <a href="">别墅客厅</a>
                                </p>
                                <p>
                                    <a href="">客餐厅</a>
                                </p>
                                <p>
                                    <a href="">家居餐厅</a>
                                </p>
                                <p>
                                    <a href="">家居吧台区</a>
                                </p>
                            </div>
                        </div>
                    </div>
                    <li class="border_own every_class_li_1">
                        <img src="https://cdn.code.znzmo.com/newSu-x/c1.png" alt="">
                        <a href="" class="category">
                            场景su模型
                        </a>
                        <span class="more">
                            <img src="https://cdn.code.znzmo.com/newSu-x/cateRight.png" alt="">
                        </span>
                        <div class="class_xiangqing_all">
                            <a href="">家装空间</a>
                            <a href="">酒店空间</a>
                            <a href="">餐饮空间</a>
                        </div>
                    </li>
                    <div class="every_class every_class_0">
                        <div class="every_class_list">
                            <div class="every_class_list_l">
                                <a href="">客/餐厅</a>
                                <img src="https://cdn.code.znzmo.com/newSu-x/cateRight.png" alt="">
                            </div>
                            <div class="every_class_list_r">
                                <p>
                                    <a href="">家居客厅</a>
                                </p>
                                <p>
                                    <a href="">别墅客厅</a>
                                </p>
                                <p>
                                    <a href="">客餐厅</a>
                                </p>
                                <p>
                                    <a href="">家居餐厅</a>
                                </p>
                                <p>
                                    <a href="">家居吧台区</a>
                                </p>
                            </div>
                        </div>
                        <div class="every_class_list">
                            <div class="every_class_list_l">
                                <a href="">客/餐厅1</a>
                                <img src="https://cdn.code.znzmo.com/newSu-x/cateRight.png" alt="">
                            </div>
                            <div class="every_class_list_r">
                                <p>
                                    <a href="">家居客厅</a>
                                </p>
                                <p>
                                    <a href="">别墅客厅</a>
                                </p>
                                <p>
                                    <a href="">客餐厅</a>
                                </p>
                                <p>
                                    <a href="">家居餐厅</a>
                                </p>
                                <p>
                                    <a href="">家居吧台区</a>
                                </p>
                            </div>
                        </div>
                    </div>
                    <li class="border_own every_class_li_2">
                        <img src="https://cdn.code.znzmo.com/newSu-x/c2.png" alt="">
                        <a href="" class="category">
                            建筑景观su模型
                        </a>
                        <span class="more">
                            <img src="https://cdn.code.znzmo.com/newSu-x/cateRight.png" alt="">
                        </span>
                        <div class="class_xiangqing_all">
                            <a href="">住宅</a>
                            <a href="">商业</a>
                            <a href="">公共</a>
                        </div>
                    </li>
                    <!-- <li class="border_own every_class_li_3">
                    <img src="https://cdn.code.znzmo.com/newSu-x/c3.png" alt="">
                    <a href="" class="category">
                        户外景观su模型
                    </a>
                    <span class="more">
                        <img src="https://cdn.code.znzmo.com/newSu-x/cateRight.png" alt="">
                    </span>
                    <div class="class_xiangqing_all">
                        <a href="">景观小品</a>
                        <a href="">小区景观</a>
                        <a href="">庭院景观</a>
                    </div>
                </li>
                <li class="border_own every_class_li_4">
                    <img src="https://cdn.code.znzmo.com/newSu-x/c4.png" alt="">
                    <a href="" class="category">
                        软装家具su模型
                    </a>
                    <span class="more">
                        <img src="https://cdn.code.znzmo.com/newSu-x/cateRight.png" alt="">
                    </span>
                    <div class="class_xiangqing_all">
                        <a href="">沙发</a>
                        <a href="">茶几</a>
                        <a href="">床具</a>
                    </div>
                </li>
                <li class="border_own every_class_li_5">
                    <img src="https://cdn.code.znzmo.com/newSu-x/c5.png" alt="">
                    <a href="" class="category">
                        陈设软饰su模型
                    </a>
                    <span class="more">
                        <img src="https://cdn.code.znzmo.com/newSu-x/cateRight.png" alt="">
                    </span>
                    <div class="class_xiangqing_all">
                        <a href="">摆件</a>
                        <a href="">墙饰墙绘</a>
                        <a href="">布艺软饰</a>
                    </div>
                </li> -->
                    <li class="border_own every_class_li_6">
                        <img src="https://cdn.code.znzmo.com/newSu-x/c6.png" alt="">
                        <a href="" class="category">
                            更多分类su模型
                        </a>
                        <span class="more">
                            <img src="https://cdn.code.znzmo.com/newSu-x/cateRight.png" alt="">
                        </span>
                        <div class="class_xiangqing_all">
                            <a href="">厨卫用品</a>
                            <a href="">构件五金</a>
                            <a href="">器材设备</a>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="leftWrapper">
                <div class="swiper" id="mainSwiper">
                    <Carousel :list="bannerList"></Carousel>
                    <div class="totalNumTips">
                        <span>累计已上架3,364,485个SU模型 最近更新时间：2022年09月14日</span>
                    </div>
                </div>
                <div class="sth-link">
                    <div class="link-item">
                        <img class="bgImg" src="https://user.file.znzmo.com/erRKetN4zn.png" alt="">
                        <div class="jgContent">
                            <div>
                                <div>新用户免费</div>
                                <img src="https://cdn.code.znzmo.com/newSu-x/jgtou.png" alt="">
                            </div>
                            <div>精品模型 限时免费</div>
                        </div>
                    </div>
                    <div class="link-item">
                        <img class="bgImg" src="https://user.file.znzmo.com/5ijwYTRsat.png" alt="">
                        <div class="jgContent">
                            <div>
                                <div>上传作品</div>
                                <img src="https://cdn.code.znzmo.com/newSu-x/jgtou.png" alt="">
                            </div>
                            <div>简单任务 轻松赚取</div>
                        </div>
                    </div>
                    <div class="link-item">
                        <img class="bgImg" src="https://user.file.znzmo.com/FcDMBhGQt3.png
                    " alt="">
                        <div class="jgContent">
                            <div>
                                <div>Enscape模型</div>
                                <img src="https://cdn.code.znzmo.com/newSu-x/jgtou.png" alt="">
                            </div>
                            <div>实时渲染 火爆全网</div>
                        </div>
                    </div>
                    <div class="link-item">
                        <img class="bgImg" src="https://user.file.znzmo.com/ksMZFJyFKX.png" alt="">
                        <div class="jgContent">
                            <div>
                                <div>效果图作品</div>
                                <img src="https://cdn.code.znzmo.com/newSu-x/jgtou.png" alt="">
                            </div>
                            <div>流行趋势 发现创意</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="w1703">
            <div class="suSeoFloor">
                <div class="headerWrapper">
                    <h2>精品推荐</h2>
                    <ul class="headerList" @mouseover="select1">
                        <li :class="active1 == 0 ? 'checked' : ''" data-index="0" data-type="1">单体</li>
                        <li :class="active1 == 1 ? 'checked' : ''" data-index="1" data-type="1">场景</li>
                        <li :class="active1 == 2 ? 'checked' : ''" data-index="2" data-type="1">建筑</li>
                    </ul>
                </div>
                <ul class="SuFloorContainerListWrapper">
                    <li>
                        <div class="imgWrapper">
                            <a href="">
                                <img src="https://image6.znzmo.com/1662710079239_5019.png?x-oss-process=style/list
                            " alt="">
                            </a>
                        </div>
                        <a href="">
                            <div class="title">现代风格药品店</div>
                        </a>
                    </li>
                    <li>
                        <div class="imgWrapper">
                            <a href="">
                                <img src="https://image6.znzmo.com/1662709024533_3176.png?x-oss-process=style/list"
                                    alt="">
                            </a>
                        </div>
                        <a href="">
                            <div class="title">现代风格亲子餐厅</div>
                        </a>
                    </li>
                    <li>
                        <div class="imgWrapper">
                            <a href="">
                                <img src="https://image6.znzmo.com/1662708303506_6674.jpeg?x-oss-process=style/list"
                                    alt="">
                            </a>
                        </div>
                        <a href="">
                            <div class="title">现代风格儿童活动空间 商场中庭</div>
                        </a>
                    </li>
                    <li>
                        <div class="imgWrapper">
                            <a href="">
                                <img src="https://image6.znzmo.com/1662802423497_7991.jpeg?x-oss-process=style/list
                            " alt="">
                            </a>
                        </div>
                        <a href="">
                            <div class="title">现代别墅客厅</div>
                        </a>
                    </li>
                    <li>
                        <div class="imgWrapper">
                            <a href="">
                                <img src="https://image6.znzmo.com/1662802164953_8071.jpeg?x-oss-process=style/list"
                                    alt="">
                            </a>
                        </div>
                        <a href="">
                            <div class="title">现代别墅客厅</div>
                        </a>
                    </li>
                    <li>
                        <div class="imgWrapper">
                            <a href="">
                                <img src="https://image6.znzmo.com/1662693399320_799.jpeg?x-oss-process=style/list
                            " alt="">
                            </a>
                        </div>
                        <a href="">
                            <div class="title">现代客厅 沙发茶几 装饰画 休闲椅沙发凳 过道暴力熊 长虹玻璃推拉门 盆栽</div>
                        </a>
                    </li>
                    <li>
                        <div class="imgWrapper">
                            <a href="">
                                <img src="https://image6.znzmo.com/1662693194589_817.jpeg?x-oss-process=style/list"
                                    alt="">
                            </a>
                        </div>
                        <a href="">
                            <div class="title">Porada现代卧室 双人床床头柜床尾凳 玻璃门衣柜 书柜 休闲椅 小吊灯</div>
                        </a>
                    </li>
                    <li>
                        <div class="imgWrapper">
                            <a href="">
                                <img src="https://image6.znzmo.com/1662998020653_7368.jpeg?x-oss-process=style/list"
                                    alt="">
                            </a>
                        </div>
                        <a href="">
                            <div class="title">现代客厅 沙发茶几</div>
                        </a>
                    </li>
                    <li>
                        <div class="imgWrapper">
                            <a href="">
                                <img src="https://image6.znzmo.com/1662897693120_2254.jpeg?x-oss-process=style/list"
                                    alt="">
                            </a>
                        </div>
                        <a href="">
                            <div class="title">现代客餐厅 沙发茶几组合 单人休闲椅 书籍 公仔 绿植 饰品摆件</div>
                        </a>
                    </li>
                    <li>
                        <div class="imgWrapper">
                            <div class="mask">
                                <div class="maskContent">
                                    <div class="maskTitle">更多室内精品模型</div>
                                    <div class="maskNumber">13318</div>
                                    <div class="watchButton">查看</div>
                                </div>
                            </div>
                            <a href="">
                                <img src="https://image6.znzmo.com/1663084355132_4569.jpeg?x-oss-process=style/list"
                                    alt="">
                            </a>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="suSeoFloor">
                <div class="headerWrapper">
                    <h2>品质专区</h2>
                    <ul class="headerList" @mouseover="select1">
                        <li :class="active2 == 0 ? 'checked' : ''" data-index="0" data-type="2" title="最新">最新</li>
                        <li :class="active2 == 1 ? 'checked' : ''" data-index="1" data-type="2">热门</li>
                    </ul>
                </div>
                <ul class="SuFloorContainerListWrapper">
                    <li>
                        <div class="imgWrapper">
                            <a href="">
                                <img src="https://image6.znzmo.com/1660202195140_6491.jpeg?x-oss-process=style/list
                            " alt="">
                            </a>
                        </div>
                        <a href="">
                            <div class="title">Century现代沙发茶几组合 布衣沙发 大平层沙发 转角沙发</div>
                        </a>
                    </li>
                    <li>
                        <div class="imgWrapper">
                            <a href="">
                                <img src="https://image6.znzmo.com/1660202098532_1901.jpeg?x-oss-process=style/list"
                                    alt="">
                            </a>
                        </div>
                        <a href="">
                            <div class="title">Century现代沙发茶几组合 布衣沙发 大平层沙发 转角沙发</div>
                        </a>
                    </li>
                    <li>
                        <div class="imgWrapper">
                            <a href="">
                                <img src="https://image6.znzmo.com/1660202053210_4255.jpeg?x-oss-process=style/list
                            " alt="">
                            </a>
                        </div>
                        <a href="">
                            <div class="title">Century现代沙发茶几组合 布衣沙发 大平层沙发 转角沙发</div>
                        </a>
                    </li>
                    <li>
                        <div class="imgWrapper">
                            <a href="">
                                <img src="https://image6.znzmo.com/1660201993105_4870.jpeg?x-oss-process=style/list
                            " alt="">
                            </a>
                        </div>
                        <a href="">
                            <div class="title">Century现代沙发茶几组合 布衣沙发 大平层沙发 转角沙发</div>
                        </a>
                    </li>
                    <li>
                        <div class="imgWrapper">
                            <a href="">
                                <img src="https://image6.znzmo.com/1660201929029_7818.jpeg?x-oss-process=style/list"
                                    alt="">
                            </a>
                        </div>
                        <a href="">
                            <div class="title">Century现代沙发茶几组合 布衣沙发 大平层沙发 转角沙发</div>
                        </a>
                    </li>
                    <li>
                        <div class="imgWrapper">
                            <a href="">
                                <img src="https://image6.znzmo.com/1660201827453_4339.jpeg?x-oss-process=style/list
                            " alt="">
                            </a>
                        </div>
                        <a href="">
                            <div class="title">Century现代沙发茶几组合 布衣沙发 大平层沙发 转角沙发</div>
                        </a>
                    </li>
                    <li>
                        <div class="imgWrapper">
                            <a href="">
                                <img src="https://image6.znzmo.com/1660201747774_5342.jpeg?x-oss-process=style/list"
                                    alt="">
                            </a>
                        </div>
                        <a href="">
                            <div class="title">Century现代沙发茶几组合 布衣沙发 大平层沙发 转角沙发</div>
                        </a>
                    </li>
                    <li>
                        <div class="imgWrapper">
                            <a href="">
                                <img src="https://image6.znzmo.com/1660231633410_8246.jpeg?x-oss-process=style/list
                            " alt="">
                            </a>
                        </div>
                        <a href="">
                            <div class="title">lock现代休闲椅 吊灯 墙饰挂画</div>
                        </a>
                    </li>
                    <li>
                        <div class="imgWrapper">
                            <a href="">
                                <img src="https://image6.znzmo.com/1660231558359_8569.jpeg?x-oss-process=style/list"
                                    alt="">
                            </a>
                        </div>
                        <a href="">
                            <div class="title">Calia现代双人沙发</div>
                        </a>
                    </li>
                    <li>
                        <div class="imgWrapper">
                            <div class="mask">
                                <div class="maskContent">
                                    <div class="maskTitle">更多最新模型</div>
                                    <div class="maskNumber">764</div>
                                    <div class="watchButton">查看</div>
                                </div>
                            </div>
                            <a href="">
                                <img src="https://image6.znzmo.com/1660231490636_5861.jpeg?x-oss-process=style/list"
                                    alt="">
                            </a>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="suFloor" style="display: none;">
                <div class="suFloorHeader">
                    <div class="suFloorHeaderTitle">
                        <h2>专辑优选</h2>
                    </div>
                    <div class="suFloorHeaderMore">
                        <a href="">更多专辑</a>
                    </div>
                </div>
                <ul class="suFloorContent">
                    <li>
                        <a href="">
                            <img src="https://image6.znzmo.com//area3d/1661324241384.jpg" alt="">
                            <div class="titleWrapper">
                                <div class="title">幼儿园内部合辑</div>
                                <div class="subTitle">该上学啦</div>
                            </div>
                        </a>
                    </li>
                    <li>
                        <a href="">
                            <img src="https://image6.znzmo.com//area3d/1660203484125.jpg" alt="">
                            <div class="titleWrapper">
                                <div class="title">落地灯合辑</div>
                                <div class="subTitle">给你的房间带来一丝光亮</div>
                            </div>
                        </a>
                    </li>
                    <li>
                        <a href="">
                            <img src="https://image6.znzmo.com//area3d/1658989616138.jpg" alt="">
                            <div class="titleWrapper">
                                <div class="title">2022年7月家装精选模型合辑</div>
                                <div class="subTitle">目光所至，皆是家的样子</div>
                            </div>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
    import Carousel from '@/components/Mswiper.vue';
    export default {
        data() {
            return {
                keyword: '',
                bannerList: [{
                        id: 1,
                        imgUrl: 'https://image4.znzmo.com/Nat53NRNmH.png?x-oss-process=style'
                    },
                    {
                        id: 2,
                        imgUrl: 'https://image4.znzmo.com/Nat53NRNmH.png?x-oss-process=style'
                    }
                ],
                active1:0,
                active2:0,
            }
        },
        methods:{
            select1(event){
                let el = event.target;
                let { index ,type } = el.dataset;

                if(index && type == 1){
                    this.active1 = index;
                }
                if(index && type == 2){
                    this.active2 = index;
                }
            }
        },
        components: {
            Carousel
        }
    };
</script>

<style lang="less" scoped>
    .sec-top {
        position: relative;
        background: #fff;

        .container {
            position: relative;
            width: 1800px;
            min-height: 170px;
            margin: 0 auto;
            display: flex;

            .logo {
                width: 276px;
                height: 68px;
                margin-top: 33px;
                margin-left: -15px;
                display: -webkit-box;
                display: -moz-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-align: center;
                -moz-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                margin-left: 10px;
            }

            .ipt_container {
                padding-top: 33px;
                margin-left: 180px;
                position: relative;
                width: 984px;
                // height: 42px;
                transition: transform 0.5s, -webkit-transform 0.5s;

                .ipt {
                    display: flex;
                    align-items: center;
                    font-size: 14px;
                    line-height: 1.5;

                    input {
                        outline: none;
                        border: 2px solid #333;
                        height: 64px;
                        line-height: 64px;
                        font-size: 18px;
                        color: #333;
                        border-radius: 8px 0px 0px 8px;
                        padding-left: 16px;
                        border-right: none;
                        padding-right: 30px;
                        position: relative;
                        width: 100%;
                        border-color: #03848A;
                    }

                    .btn {
                        height: 64px;
                        line-height: 64px;
                        width: 144px;
                        flex-shrink: 0;
                        margin-left: 0;
                        border-radius: 0px 8px 8px 0px;
                        font-size: 20px;
                        font-weight: bold;
                        border: none;
                        color: #fff;
                        display: -webkit-box;
                        display: -moz-box;
                        display: -ms-flexbox;
                        display: flex;
                        -webkit-box-pack: center;
                        -moz-box-pack: center;
                        -ms-flex-pack: center;
                        justify-content: center;
                        -webkit-box-align: center;
                        -moz-box-align: center;
                        -ms-flex-align: center;
                        align-items: center;
                        border-color: #03848A;
                        background-color: #03848A;
                        text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
                        -webkit-box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
                        box-shadow: 0 2px 0 rgb(0 0 0 / 5%);

                        span {
                            margin-left: 3px;
                            letter-spacing: 5px;
                        }
                    }
                }

                .ipt_more {
                    padding-top: 11px;
                    margin-left: -15px;
                    font-size: 12px;
                    white-space: nowrap;

                    a {
                        font-size: 14px;
                        margin-left: 20px;
                        color: #999;
                    }

                    .hot {
                        color: #cf2c2f;
                    }
                }
            }
        }
    }

    .type-box {
        position: relative;

        width: 1800px;
        margin: 0 auto;
        margin-top: 24px;
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
        -moz-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        // -webkit-box-align: center;
        // -moz-box-align: center;
        // -ms-flex-align: center;
        // align-items: center;

        .su_theme_category {
            background-color: #fff;
            width: 341px;
            position: relative;
            // height: 605px;
            background-color: #fff;
            z-index: 999;
            -webkit-transition: -webkit-transform 0.5s;
            transition: -webkit-transform 0.5s;
            transition: transform 0.5s;
            transition: transform 0.5s, -webkit-transform 0.5s;
            border-radius: 8px;

            .every_class_ul {
                position: relative;
                // height: 605px;
                padding-top: 12px;

                .border_own {
                    width: 100%;
                    height: 84px;
                    margin-top: 0;
                    padding-bottom: 0.13rem;
                    padding-top: 0.11rem;
                    padding-left: 0.24rem;
                    -webkit-box-sizing: border-box;
                    -moz-box-sizing: border-box;
                    box-sizing: border-box;
                    cursor: pointer;
                    -webkit-transition: all 0.3s;
                    transition: all 0.3s;
                    border-bottom: 1px solid #f7f7f7;
                    padding-top: 16px;
                    padding-left: 21px;
                    position: relative;

                    &>img {
                        position: absolute;
                        vertical-align: -6px;
                    }

                    .category {
                        margin-left: 42px;
                        font-size: 20px;
                        font-weight: bold;
                        color: #333 !important;
                        width: 60%;
                        margin-right: 0;
                        margin-top: 3px;
                    }

                    .more {
                        float: right;
                        margin-right: 30px;
                        color: #333 !important;
                    }

                    .class_xiangqing_all {
                        width: 100%;
                        margin: auto;
                        display: flex;
                        flex-flow: wrap;
                        padding-left: 42px;

                        a {
                            position: relative;
                            width: auto;
                            margin-right: 16px;
                            font-size: 14px;
                            color: #999;
                            margin-top: 12px;
                        }
                    }

                    &:hover {
                        transform: translateX(2px);
                        background-color: #fff;
                        box-shadow: rgb(0 0 0 / 10%) 0 4px 12px 0;
                    }

                }

                .every_class {
                    position: absolute;
                    top: 0;
                    left: 300px;
                    border: 0;
                    padding-top: 18px;
                    padding-left: 36px;
                    width: 920px;
                    height: 605px;
                    background: #fff;
                    z-index: 1000;
                    overflow: hidden;
                    float: left;
                    -webkit-box-sizing: border-box;
                    -moz-box-sizing: border-box;
                    box-sizing: border-box;
                    opacity: 0;
                    pointer-events: none;
                    -webkit-transition: opacity 0.3s;
                    transition: opacity 0.3s;
                    -webkit-box-shadow: 0px 4px 12px 0px rgb(0 0 0 / 10%);
                    box-shadow: 0px 4px 12px 0px rgb(0 0 0 / 10%);
                    border-radius: 8px;

                    .every_class_list {
                        width: 100%;
                        -webkit-box-sizing: border-box;
                        -moz-box-sizing: border-box;
                        box-sizing: border-box;
                        overflow: hidden;
                        margin-bottom: 20px;
                        min-height: 30px;

                        .every_class_list_l {
                            float: left;
                            text-align: right;

                            a {
                                color: #333;
                                font-size: 14px;
                                font-weight: bold;
                                margin-right: 0;
                                position: relative;
                                display: inline-block;
                                width: 75px;
                                height: 15px;
                                line-height: 1;
                                text-align: justify;
                                -moz-text-align-last: justify;
                                text-align-last: justify;
                                overflow: hidden;
                            }

                            img {
                                vertical-align: baseline;
                            }
                        }

                        .every_class_list_r {
                            margin-left: 47px;
                            border-bottom: none;
                            width: 725px;
                            float: left;
                            min-height: 15px;

                            p {
                                margin-right: 32px;
                                margin-bottom: 16px;
                                display: block;
                                word-break: keep-all;
                                float: left;
                                line-height: 1;
                                white-space: nowrap;

                                a {
                                    color: #333;
                                    font-size: 14px;
                                    font-weight: normal;
                                }
                            }
                        }
                    }

                    &:hover {
                        opacity: 1;
                        pointer-events: auto;
                    }
                }

                .border_own.active {
                    transform: translateX(2px);
                    background-color: #fff;
                    box-shadow: rgb(0 0 0 / 10%) 0 4px 12px 0;
                }

                .border_own:hover+.every_class {
                    opacity: 1;
                    pointer-events: auto;
                }

                .border_own.active+.every_class {
                    opacity: 1;
                    pointer-events: auto;
                }

            }
        }

        .leftWrapper {
            display: -webkit-box;
            display: -moz-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: justify;
            -moz-box-pack: justify;
            -ms-flex-pack: justify;
            justify-content: space-between;
            -webkit-box-align: center;
            -moz-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -moz-box-orient: vertical;
            -moz-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
            // height: 605px;

            .swiper {
                width: 1435px;
                position: relative;
                background-color: #999;
                overflow: hidden;
                border-radius: 8px;
                // height: 514px;

                

                .slick-dots {
                    position: absolute;
                    bottom: 16px;
                    display: block;
                    width: 100%;
                    height: 6px;
                    margin: 0;
                    padding: 0;
                    text-align: center;
                    list-style: none;

                    li {
                        position: relative;
                        display: inline-block;
                        margin: 0 6px;
                        padding: 0;
                        text-align: center;
                        vertical-align: top;

                        button {
                            height: 6px;
                            width: 24px;
                            background: #fff;
                            -webkit-box-shadow: 0px 2px 4px rgb(0 0 0 / 20%);
                            box-shadow: 0px 2px 4px rgb(0 0 0 / 20%);
                            opacity: 0.41;
                            border-radius: 3px;
                            font-size: 0;
                            border: 0;
                            color: transparent;
                            display: block;
                            transition: all .5s;
                            outline: none;
                            cursor: pointer;
                        }
                    }

                    li.slick-active {
                        button {
                            width: 44px;
                            opacity: 0.81;
                        }
                    }
                }

                .totalNumTips {
                    position: absolute;
                    bottom: 0px;
                    left: 0px;
                    height: 29px;
                    background: rgba(51, 51, 51, 0.6);
                    border-radius: 0px 8px 0px 8px;

                    span {
                        height: 14px;
                        font-size: 14px;

                        font-weight: 400;
                        color: #fffefe;
                        line-height: 29px;
                        margin-left: 11px;
                        margin-right: 21px;
                    }
                }
            }

            .sth-link {
                width: 1435px;
                -webkit-box-pack: justify;
                -moz-box-pack: justify;
                -ms-flex-pack: justify;
                justify-content: space-between;
                margin-top: 24px;
                display: -webkit-box;
                display: -moz-box;
                display: -ms-flexbox;
                display: flex;
                position: relative;

                .link-item {
                    width: 340px;
                    height: 80px;
                    transition: all 0.5s;
                    border-radius: 8px;
                    overflow: hidden;
                    position: relative;

                    .bgImg {
                        border-radius: 8px;
                        width: 100%;
                        height: 100%;
                        position: absolute;
                    }

                    .jgContent {
                        position: absolute;
                        z-index: 2;
                        padding: 15px 20px;

                        div:first-child {
                            display: -webkit-box;
                            display: -moz-box;
                            display: -ms-flexbox;
                            display: flex;
                            font-size: 16px;
                            align-items: center;
                            color: #333;
                            font-weight: bold;

                            img {
                                position: relative;
                                margin-left: 5px;
                                width: 14px;
                                height: 14px;
                            }
                        }

                        div:nth-child(2) {
                            color: #999;
                            font-size: 14px;
                            margin-top: 9px;
                        }
                    }
                }
            }
        }
    }


    .suSeoFloor {
        // height: 690px;
        margin-top: 90px;
        margin-bottom: 90px;
        // overflow: hidden;
        position: relative;

        .headerWrapper {
            display: flex;
            align-items: center;

            h2 {
                font-size: 28px;
                font-weight: 600;
                color: #333;
                line-height: 40px;
            }

            .headerList {
                display: flex;
                align-items: center;
                margin-left: 70px;

                li {
                    font-size: 20px;

                    font-weight: 400;
                    color: #333;
                    line-height: 20px;
                    margin-right: 40px;
                    cursor: pointer;
                    border-bottom: 2px solid transparent;
                    padding-bottom: 8px;
                    margin-top: 8px;
                }

                .checked {
                    border-bottom: 2px solid #00868c;
                    padding-bottom: 8px;
                    margin-top: 8px;
                    color: #00868c;
                }
            }
        }

        .SuFloorContainerListWrapper {
            display: -webkit-box;
            display: -moz-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: center;
            -moz-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
            -webkit-box-align: center;
            -moz-box-align: center;
            -ms-flex-align: center;
            // align-items: center;
            // position: absolute;
            z-index: 200;

            li {
                margin-right: 24px;
                margin-top: 48px;
                display: -webkit-box;
                display: -moz-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-pack: space-betwee;
                -moz-box-pack: space-betwee;
                -ms-flex-pack: space-betwee;
                justify-content: space-betwee;
                -webkit-box-align: start;
                -moz-box-align: start;
                -ms-flex-align: start;
                align-items: flex-start;
                -webkit-box-orient: vertical;
                -webkit-box-direction: normal;
                -moz-box-orient: vertical;
                -moz-box-direction: normal;
                -ms-flex-direction: column;
                flex-direction: column;
                cursor: pointer;
                position: relative;
                border-radius: 8px;

                .imgWrapper {
                    width: 340px;
                    height: 252px;
                    border-radius: 8px;
                    overflow: hidden;

                    img {
                        border-radius: 8px;
                        width: 340px;
                        height: 252px;
                        -webkit-transition: all 1s;
                        transition: all 1s;
                        &:hover{
                            transform: scale(1.1);
                        }
                    }
                }

                .title {
                    margin-top: 16px;
                    font-weight: 600;
                    font-size: 14px;
                    color: #333;
                    line-height: 14px;
                    width: 340px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    -webkit-transition: all 0.3s;
                    transition: all 0.3s;
                }
            }

            li:nth-child(5n) {
                margin-right: 0;
            }

            .mask {
                position: absolute;
                width: 100%;
                height: 252px;
                top: 0;
                left: 0;
                background: rgba(0, 0, 0, 0.6);
                display: -webkit-box;
                display: -moz-box;
                display: -ms-flexbox;
                display: flex;
                -ms-flex-pack: distribute;
                justify-content: space-around;
                -webkit-box-align: center;
                -moz-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                -webkit-box-orient: vertical;
                -webkit-box-direction: normal;
                -moz-box-orient: vertical;
                -moz-box-direction: normal;
                -ms-flex-direction: column;
                flex-direction: column;
                border-radius: 8px;
                z-index: 99;

                .maskContent {
                    height: 150px;
                    display: -webkit-box;
                    display: -moz-box;
                    display: -ms-flexbox;
                    display: flex;
                    -ms-flex-pack: distribute;
                    justify-content: space-around;
                    -webkit-box-align: center;
                    -moz-box-align: center;
                    -ms-flex-align: center;
                    align-items: center;
                    -webkit-box-orient: vertical;
                    -webkit-box-direction: normal;
                    -moz-box-orient: vertical;
                    -moz-box-direction: normal;
                    -ms-flex-direction: column;
                    flex-direction: column;

                    .maskTitle {
                        font-size: 18px;
                        font-weight: 300;
                        color: #fff;
                        line-height: 25px;
                    }

                    .maskNumber {
                        font-size: 32px;
                        font-weight: bold;
                        color: #fff;
                        line-height: 37px;
                    }

                    .watchButton {
                        font-size: 16px;
                        font-weight: 600;
                        color: #fff;
                        line-height: 37px;
                        border-radius: 20px;
                        border: 1px solid rgba(255, 255, 255, 0.6);
                        width: 120px;
                        height: 40px;
                        text-align: center;
                    }
                }
            }
        }
    }

    .suFloor {
        margin-top: 90px;
        margin-bottom: 90px;

        .suFloorHeader {
            display: -webkit-box;
            display: -moz-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: justify;
            -moz-box-pack: justify;
            -ms-flex-pack: justify;
            justify-content: space-between;
            -webkit-box-align: center;
            -moz-box-align: center;
            -ms-flex-align: center;
            align-items: center;

            .suFloorHeaderTitle {
                font-size: 40px;
                font-weight: 600;
                color: #333;
                line-height: 40px;
                margin-right: 64px;

                h2 {
                    font-size: 28px;
                    font-weight: 600;
                    color: #333;
                    line-height: 40px;
                }
            }

            .suFloorHeaderMore {
                a {
                    cursor: pointer;
                    font-size: 16px;
                    font-weight: 600;
                    color: #333;
                    line-height: 16px;

                    &:after {
                        content: '';
                        background: url(https://cdn.code.znzmo.com/newSu/floor9-icon.png) no-repeat center;
                        width: 16px;
                        height: 16px;
                        display: inline-block;
                        vertical-align: -3px;
                        margin-left: 5px;
                    }
                }
            }
        }

        .suFloorContent {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 40px;

            li {
                width: 584px;
                height: 320px;
                border-radius: 8px;
                overflow: hidden;
                margin-right: 24px;
                display: flex;
                justify-content: flex-end;
                flex-direction: column;
                position: relative;
                cursor: pointer;
                -webkit-transition: all 0.3s;
                transition: all 0.3s;

                img {
                    width: 584px;
                    height: 320px;
                    -webkit-transition: all 1s;
                    transition: all 1s;
                }

                .titleWrapper {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    height: 108px;
                    padding-top: 20px;
                    padding-bottom: 24px;
                    padding-left: 24px;
                    background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0)), to(rgba(0, 0, 0, 0.6)));
                    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%);
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;

                    .title {
                        font-size: 24px;
                        font-weight: 600;
                        color: #fff;
                        line-height: 32px;
                        margin-bottom: 8px;
                    }

                    .subTitle {
                        font-size: 16px;
                        font-weight: 600;
                        color: #fff;
                        line-height: 24px;
                    }
                }
            }
        }
    }

    // @media only screen and (max-width: 1800px) {
    //     .sec-top .container {
    //         width: 1500px;
    //     }

    //     .type-box {
    //         width: 1500px;
    //         align-items: flex-start;

    //         .su_theme_category {
    //             width: 254px;

    //             // height: 515px;
    //             .every_class_ul {
    //                 .border_own {
    //                     .category {
    //                         font-size: 16px;
    //                         margin-left: 36px;
    //                     }

    //                     .class_xiangqing_all {
    //                         padding-left: 36px;

    //                         a {
    //                             font-size: 12px;
    //                         }
    //                     }
    //                 }

    //                 .every_class {
    //                     left: 255px;
    //                 }
    //             }
    //         }

    //         .leftWrapper {
    //             width: 1230px;

    //             .swiper {
    //                 width: 1230px;

    //                 // height: 426px;
    //                 img {
    //                     width: 100%;
    //                     height: 100%;
    //                     object-fit: cover;
    //                 }
    //             }

    //             .sth-link {
    //                 width: 100%;

    //                 .link-item {
    //                     width: 288px;
    //                 }
    //             }
    //         }
    //     }

    //     .suSeoFloor .SuFloorContainerListWrapper li {
    //         margin-right: 18px;
    //         margin-top: 24px;
    //         width: 285px;

    //         .imgWrapper {
    //             width: 285px;
    //             height: 220px;

    //             img {
    //                 width: 100%;
    //                 height: 100%;
    //             }

    //             .mask {
    //                 height: 220px;
    //             }
    //         }

    //         .title {
    //             width: 285px;
    //         }
    //     }
    // }

    // @media only screen and (max-width: 1500px) {
    //     .sec-top .container {
    //         width: 1200px;
    //     }

    //     .type-box {
    //         width: 1200px;
    //         align-items: flex-start;

    //         .su_theme_category {
    //             width: 254px;

    //             .every_class_ul {
    //                 .border_own {
    //                     .category {
    //                         font-size: 16px;
    //                         margin-left: 36px;
    //                     }

    //                     .class_xiangqing_all {
    //                         padding-left: 36px;

    //                         a {
    //                             font-size: 12px;
    //                         }
    //                     }
    //                 }

    //                 .every_class {
    //                     left: 255px;
    //                 }
    //             }
    //         }

    //         .leftWrapper {
    //             width: 930px;

    //             .swiper {
    //                 width: 930px;

    //                 img {
    //                     width: 100%;
    //                     height: 100%;
    //                     object-fit: cover;
    //                 }
    //             }

    //             .sth-link {
    //                 width: 100%;

    //                 .link-item {
    //                     width: 213px;
    //                 }
    //             }
    //         }
    //     }

    //     .suSeoFloor .SuFloorContainerListWrapper li {
    //         margin-right: 18px;
    //         margin-top: 24px;
    //         width: 225px;

    //         .imgWrapper {
    //             width: 225px;
    //             height: 175px;

    //             img {
    //                 width: 100%;
    //                 height: 100%;
    //             }

    //             .mask {
    //                 height: 175px;
    //             }
    //         }

    //         .title {
    //             width: 225px;
    //         }
    //     }

    // }

    // @media only screen and (max-width: 1000px) {
    //     .sec-top {
    //         display: none;
    //     }

    //     .type-box {
    //         width: 100%;
    //         padding: 0 10px;

    //         .leftWrapper {
    //             display: none;
    //         }

    //         .su_theme_category {
    //             width: 100%;
    //             height: auto;
    //             z-index: 99;

    //             .every_class_ul {
    //                 height: auto;

    //                 .every_class {
    //                     display: none;
    //                 }
    //             }
    //         }
    //     }

    //     .suSeoFloor {
    //         margin-top: 30px;

    //         .headerWrapper {
    //             flex-wrap: wrap;
    //             justify-content: center;
    //             display: block;

    //             h2 {
    //                 margin-bottom: 20px;
    //                 text-align: center;
    //             }

    //             .headerList {
    //                 margin-left: 0;
    //                 justify-content: center;

    //                 li:last-child {
    //                     margin-right: 0;
    //                 }
    //             }
    //         }

    //         .SuFloorContainerListWrapper {
    //             li {
    //                 width: calc(50% - 5px);
    //                 margin-right: 10px;

    //                 .imgWrapper {
    //                     width: 100%;
    //                     height: auto;
    //                     border-radius: 4px;

    //                     img {
    //                         border-radius: 4px;
    //                     }

    //                     .mask {
    //                         height: auto;
    //                         right: 0;
    //                         bottom: 0;
    //                         margin-bottom: 30px;
    //                     }
    //                 }

    //                 &>a {
    //                     width: 100%;
    //                 }

    //                 .title {
    //                     width: 100%;
    //                 }
    //             }

    //             li:nth-child(5n) {
    //                 margin-right: 10px;
    //             }

    //             li:nth-child(2n) {
    //                 margin-right: 0;
    //             }
    //         }
    //     }
    // }
</style>